const NaturalPersonType  = [
    {
        value: 1,
        label: 'Cliente'
    },
    {
        value: 2,
        label: 'Proveedor'
    },
    {
        value: 3,
        label: 'Colaborador'
    },
    {
        value: 4,
        label: 'Otros'
    }
]
const JuridicPersonType  = [
    {
        value: 1,
        label: 'Cliente'
    },
    {
        value: 2,
        label: 'Proveedor'
    },
    {
        value: 4,
        label: 'Otros'
    }
]
const PersonType = [
    NaturalPersonType,
    JuridicPersonType
]
export default PersonType;
import React, { useMemo } from "react";
// import { useParams } from 'react-router-dom';
import { Divider, Form, Input, Select, Checkbox } from "antd";
import Terms from "./Terms";
import DocumentType from "../model/DocumentType";
import PersonType from "../model/PersonType";
import { Controller } from "react-hook-form";

const FormNaturalPerson =  ({ active, control, errors, watch })  => {
  const maxLength = useMemo(() => (active ? 250 : undefined), [active]);
  // const { parameter } = useParams();

  const watchPersonType = watch("personType");
  const watchDocumentType = watch("documentType");

  const needDocumentNumber  = useMemo(
    () => (watchDocumentType === 1 || watchDocumentType === 2 || watchDocumentType === 3 ),
    [watchDocumentType]
  );

  const oncheckpers = async () => {
    try {

      var miCheckboxlegal = document.getElementsByName('naturalAcceptanceTerms')[0];
        
      
        if(miCheckboxlegal.checked) {
          document.getElementById("btnregistrar").disabled = false;
        } else {
          document.getElementById("btnregistrar").disabled = true;
        };
    
      
    } catch (error) {
      console.error(error);
      return  error;
    }
  }

  const documentLength = useMemo(() => {
      if (!active) return undefined;
      return {
        1: 8,
        2: 11,
        3: 9,
      }[watchDocumentType];
    }, 
    [watchDocumentType,active]
  );

  const documentPattern = useMemo(() => {
    if (!active) return undefined;
    return {
      1: /^[0-9]*$/,
      2: /^[0-9]*$/,
      3: undefined,
    }[watchDocumentType];
  }, [watchDocumentType, active]);

  const needDescription = useMemo(
    () => watchPersonType === 4,
    [watchPersonType]
  );

  // useEffect(() => {
  //   console.log('parameter', parameter)
  // }, [parameter])

  return (
    <>
      <Form
        labelCol={{ span: 24 }}
        wrapperCol={{ span: 24 }}
        layout="horizontal"
        style={{ paddingTop: 10 }}
      >
        <Form.Item 
         hidden="true" 
        help={errors.id_portal ? '' : ''} validateStatus={errors.id_portal ? 'error' : ''}  >
          <Controller
            name="id_portal"
            control={control}
            rules={{ required: active, maxLength: maxLength }}
            render={({ field }) => (
              <Input {...field}   className="inputForm" />
            )}
          />
        </Form.Item>
        <Form.Item 
        hidden="true" 
        help={errors.id_portal ? '' : ''} validateStatus={errors.id_portal ? 'error' : ''}  >
          <Controller
            name="idtoken"
            control={control}
            rules={{ required: active, maxLength: maxLength }}
            render={({ field }) => (
              <Input {...field}   className="inputForm" />
            )}
          />
        </Form.Item>
        <Form.Item help={errors.name ? '' : ''} validateStatus={errors.name ? 'error' : ''}>
          <Controller
            name="name"
            control={control}
            rules={{ required: active, maxLength: maxLength }}
            render={({ field }) => (
              <Input {...field} disabled="true" placeholder="Nombre" className="inputForm" maxLength={250}/>
            )}
          />
        </Form.Item>
        <Form.Item help={errors.surname ? '' : ''} validateStatus={errors.surname ? 'error' : ''}>
          <Controller
            name="surname"
            control={control}
            rules={{ required: active, maxLength: maxLength }}
            render={({ field }) => (
              <Input {...field} disabled="true" placeholder="Apellidos" className="inputForm" maxLength={250}/>
            )}
          />
        </Form.Item>
        <Form.Item label="Tipo Documento" className="labelSelect" help={errors.documentType ? '' : ''} validateStatus={errors.documentType ? 'error' : ''}>
          <Controller
            name="documentType"
            control={control}
            defaultValue={null}
            rules={{ required: active }}
            render={({ field }) => (
              <Select
                {...field}
                className="selectForm"
                placeholder="Elegir"
                options={DocumentType}
                disabled="true"
              ></Select>
            )}
          />
        </Form.Item>
        {needDocumentNumber && (
          <Form.Item help={errors.documentNumber ? '' : ''} validateStatus={errors.documentNumber ? 'error' : ''}>
            <Controller
              name="documentNumber"
              control={control}
              defaultValue=""
              rules={{ required: needDocumentNumber, maxLength: documentLength,pattern: documentPattern, minLength: documentLength}}
              render={({ field }) => (
                <Input {...field} disabled="true" placeholder="Introduzca número de documento" className="inputForm" maxLength={documentLength} minLength={documentLength} pattern={documentPattern} />
              )}
            />
          </Form.Item>
        )}
        <Divider>Ayúdanos a Identificarte</Divider>
        <Form.Item label="Eres:" className="labelSelect" help={errors.personType ? '' : ''} validateStatus={errors.personType ? 'error' : ''}>
          <Controller
            name="personType"
            control={control}
            rules={{ required: active }}
            render={({ field }) => (
              <Select
                {...field}
                className="selectForm"
                placeholder="Elegir"
                defaultValue={null}
                options={PersonType[0]}
                disabled="true"
              ></Select>
            )}
          />
        </Form.Item>
      
        {needDescription && (
          <Form.Item help={errors.personTypeDescription ? '' : ''} validateStatus={errors.personTypeDescription ? 'error' : ''}>
            <Controller
              name="personTypeDescription"
              control={control}
              defaultValue=""
              rules={{ required: needDescription, maxLength: maxLength }}
              render={({ field }) => (
                <Input {...field} placeholder="Otros" className="inputForm" maxLength={250} />
              )}
            />
          </Form.Item>
        )}

        <Form.Item help={errors.naturalAcceptanceTerms ? '' : ''} validateStatus={errors.naturalAcceptanceTerms ? 'error' : ''}>
          <div className="divTerms">
            <Controller
              name="naturalAcceptanceTerms"
              control={control}
              rules={{ required: active }}
              render={({ field }) => (
                <Checkbox
                  {...field}
                  checked={field.value}
                  onChange={field.onChange}
                  className="checkForm"
                  id="chkterminos"
                  onClick={oncheckpers}
                >
                  He leído y estoy de acuerdo con la Política de <strong>Privacidad</strong> de <strong>Leche Gloria S.A.</strong>
                </Checkbox>
              )}
            />
          </div>
        </Form.Item>
        <Terms />
      </Form>
    </>
  );
};

export default FormNaturalPerson;

import axios from 'axios';
import {
  getCurrentISOString,
  getIPAddressOrNull
} from "../utils";

export const RegistrationServices = async (dataRaw,token,typeForm) => {
    const ipAddress = await getIPAddressOrNull();
    const fechaHora = getCurrentISOString();
    
    try {
      let data ={}
      if (typeForm===1){
        data = JSON.stringify({
          // tipoDocumento: dataRaw.documentType,
          // tipoPersona: typeForm,
          // tipoRegistrador: dataRaw.personType,
          // numeroDocumento: dataRaw.documentNumber,
          // nombre: fieldIsNull(dataRaw.name),
          // apellido: fieldIsNull(dataRaw.surname),
          // razonsocial: fieldIsNull(dataRaw.businessName),
          ip: ipAddress,
          fechaHora: fechaHora,
          flagPoliticaSeguridad: true,
          // descripcionAdicional: dataRaw.personTypeDescription,
          id: dataRaw.id_portal
          // tokenid: dataRaw.tokenid
        });
        
      }
      if (typeForm===2){
        data = JSON.stringify({
          // tipoDocumento: 2,
          // tipoPersona: typeForm,
          // tipoRegistrador: 1,
          // numeroDocumento: dataRaw.businessDocumentNumber,
          // nombre: fieldIsNull(dataRaw.name),
          // apellido: fieldIsNull(dataRaw.surname),
          // razonsocial: fieldIsNull(dataRaw.businessName),
          ip: ipAddress,
          fechaHora: fechaHora,
          flagPoliticaSeguridad: true,
          // descripcionAdicional: fieldIsNull(dataRaw.legalPersonTypeDescription),
          id: dataRaw.id_portal
          // tokenid: dataRaw.tokenid
        });
        
      }
      const options = {
        url: process.env.REACT_APP_API_URL+"cliente/registrarCliente",
        method: "POST",
        data: data,
        headers: {
          // "Spring.Cloud.Function.Definition": "register",
          "Content-Type": "application/json",
          // "Access-Control-Allow-Origin": "*",
          "Authorization": `Bearer ${token}`
        },
      };
      const response = await axios.request(options);
      console.log("response..."); 
      console.log(response); 
      return response;
    } catch (error) {
      console.error(error);
      return  error;
    }
  };
  
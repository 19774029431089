import axios from 'axios';


export const getIPAddress = async () => {
  try {
    const response = await axios.get('https://api64.ipify.org?format=json');
    return response.data.ip;
  } catch (error) {
    return null;
  }
};

export const getIPAddressOrNull = async () => {
    const ipAddress = await getIPAddress();
    return ipAddress || null;
};

export const getCurrentISOString = () => {
  const currentDate = new Date();
  const timezoneOffsetMinutes = -300; 

  const adjustedTimestamp = new Date(currentDate.getTime() + timezoneOffsetMinutes * 60 * 1000);

  const formattedTimestamp = adjustedTimestamp.toISOString().replace('Z', '');

  return formattedTimestamp;
  };

export const convertStringToInt = (stringValue) => {
  const parsedInt = parseInt(stringValue, 10);

  if (!isNaN(parsedInt)) {
    return parsedInt;
  } else {
    return null;
  }
};

export const fieldIsNull = (stringValue) => {
  if (stringValue === undefined) {
    return null;
  } else {
    return stringValue;
  }
};
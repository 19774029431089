import React, {Component} from "react";

class FooterComponent extends Component{
    render (){
        return(
            <div className="fontFooter">
                {'©'}
                {' '}
                {new Date().getFullYear()}
                {' '}
                Marketing Grupo Gloria, Perú.
                {' '} <br/>
                Todos los Derechos Reservados.
            </div>
        )
    }
}
export default FooterComponent;
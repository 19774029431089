const DocumentType  = [
    {
        value: 1,
        label: 'DNI'
    },
    {
        value: 2,
        label: 'RUC'
    },
    {
        value: 3,
        label: 'Pasaporte'
    }
  ]
  
export default DocumentType;
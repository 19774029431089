import axios from 'axios';

export const ReadServices = async (tokenid,token) => {
    
    try {
      const options = {
        url: process.env.REACT_APP_API_URL+"cliente/consultarCliente?id="+tokenid,
        method: "GET",
        headers: {
          // "Spring.Cloud.Function.Definition": "register",
          "Content-Type": "application/json",
          // "Access-Control-Allow-Origin": "*",
          "Authorization": `Bearer ${token}`
        },
      };
      const response = await axios.request(options);
      console.log("response..."); 
      console.log(response.data); 
     
      return response;
    } catch (error) {
      console.error(error);
      return  error;
    }
  };

 

  // var minaturalCheckbox = document.getElementById('naturalAcceptanceTerms');
  // minaturalCheckbox.addEventListener('click', function() {
  //   if(minaturalCheckbox.checked) {
  //     document.getElementById("btnregistrar").disabled = false;
  //   } else {
  //     document.getElementById("btnregistrar").disabled = true;
  //   }
  // });
  
import { BankOutlined, UserOutlined } from '@ant-design/icons';
import { Alert, Button, Card, Modal, Result, Spin, Tabs } from "antd";
import React, { useEffect, useState } from "react";
import { useForm } from "react-hook-form";
import { AuthorizationServices } from "../services/AuthorizationServices";
import { ReadServices } from "../services/ReadServices";
import { RegistrationServices } from "../services/RegistrationServices";
import FormJuridicPerson from "./FormJuridicPerson";
import PersonaNaturalForm from "./FormNaturalPerson";

const FormRegister = () => {
  const [openProcess, setOpenProcess] = useState(false);
  let defaultForm =  {
    naturalAcceptanceTerms: false,
    legalAcceptanceTerms:false,
    name:"",
    surname: "",
    businessName: "",
    documentType: null,
    documentNumber:"",
    businessDocumentNumber:"",
    legalPersonType: null,
    personType: null,
    personTypeDescription: "",
    legalPersonTypeDescription: "",
    id_portal:""
  }
  const {
    control,
    formState: { errors },
    setValue,
    handleSubmit,
    reset,
    watch,
  } = useForm({});

  const [defaultTabactive, SetDefaultTabactive] = useState('1')
  const [isNaturalPerson, setIsNaturalPerson] = useState(false);
  const [isLegalPerson, setIsLegalPerson] = useState(false);
  const [isProcess, setProcess] = useState(false);
  const [isError, setError] = useState(false);
  const [isButtonProcess, setButtonProcess] = useState(false);

  const onError = (data) => {
    const messageError = {
      title: "Error en el formulario de registro de datos",
      content: (
        <ol className="directions">
          <li>Validar campos obligatorios.</li>
          <li>Validar formato de campos.</li>
          <li>Longitud de campos inválido.</li>
        </ol>
      ),
    };
    Modal.error(messageError);
  };

  const onSubmit = async (data) => {
    try {
      setOpenProcess(true);
      // setButtonProcess(false);
      
      
      if (isLegalPerson){
        //alert('entro a legal');
        var miCheckboxlegal = document.getElementsByName('legalAcceptanceTerms')[0];
        if(!miCheckboxlegal.checked ) {
          //alert('entro a if legal');
          
          setOpenProcess(false);
          
          const messageError = {
            title: "Error en el formulario de registro de datos",
            content: (
              <ol className="directions">
                <li>Validar campos obligatorios.</li>
               
              </ol>
            ),
          };
          Modal.error(messageError);
          //setError(true);
          return;
        }
      }
      else{
        //alert('entro a persona');
        var miCheckbox = document.getElementsByName('naturalAcceptanceTerms')[0];

        if(!miCheckbox.checked ) {
          //alert('entro a if persona');
          
          setOpenProcess(false);
          const messageError = {
            title: "Error en el formulario de registro de datos",
            content: (
              <ol className="directions">
                <li>Validar campos obligatorios.</li>
               
              </ol>
            ),
          };
          Modal.error(messageError);
          //setError(true);
          return;
        }
      }
      
      //deshabilitar el checkbox luego de registrar
      if (isLegalPerson){
        var miCheckboxlegal = document.getElementsByName('legalAcceptanceTerms')[0];
        miCheckboxlegal.disabled = true;
      }else{
        var miCheckbox = document.getElementsByName('naturalAcceptanceTerms')[0];
        miCheckbox.disabled = true;
      }
      
      
      
      
    
      const response = await AuthorizationServices();
      const typeForm = isNaturalPerson ? 1 : 2;
      const register = await RegistrationServices(
        data,
        response.data.access_token,
        typeForm
      );
      document.getElementById("btnregistrar").disabled = true;
        console.log(register);
      setOpenProcess(false);
     /* if (register ===401){
        console.log("UnAuthorizated");
        setError(true);
      }else{
        setProcess(true);
      }*/
      setProcess(true);
      
      // reset(defaultForm);
    } catch (error) {
      console.log(error);
      setOpenProcess(false);
      setButtonProcess(false);
      Modal.error({
        title: "Formulario de Registro",
        content: "Los datos del formulario no se pudieron registrar.",
      });
      console.log(error);
    }
  };

  const handleTabChange = (tabKey) => {
    setIsNaturalPerson(tabKey === "1");
    setIsLegalPerson(tabKey === "2");
  
    // reset(defaultForm);
  };

  

  useEffect( () => {
    
    // Obtén la cadena de consulta de la URL
    const queryString = window.location.search;

    // Crea un objeto URLSearchParams para acceder a los parámetros
    const params = new URLSearchParams(queryString);

    var respuesta = null;
    const tokenidParam = params.get('tokenid');

    (async () => {
      
      const responsetoken = await AuthorizationServices();
      const readresponse = await  ReadServices(
        tokenidParam,
        responsetoken.data.access_token
      );

      
        SetDefaultTabactive(String(readresponse.data.data.tipoDocumento));
       //alert(String(readresponse.data.tipoDocumento));
      
      if (readresponse.data.data.tipoPersona =='1'){
          document.getElementById("btnregistrar").disabled = true;
          const id = readresponse.data.data.id;
          const tokenid = readresponse.data.data.tokenid;
          const nombreParam = readresponse.data.data.nombre;
          const apellidoParam = readresponse.data.data.apellido
          const tipodocumento = readresponse.data.data.tipoDocumento;
          const tipocliente = readresponse.data.data.tipoRegistrador;
          const numdocumento = readresponse.data.data.numeroDocumento;
          const flagpolitica = readresponse.data.data.flagPoliticaSeguridad;
          if(flagpolitica){
            document.getElementById("btnregistrar").disabled = true;
            // var miCheckboxlegal = document.getElementsByName('naturalAcceptanceTerms')[0];
            // miCheckboxlegal.disabled = true;
            document.getElementById("chkterminos").disabled = true;
          }
          setValue('name', nombreParam);
          setValue('naturalAcceptanceTerms', flagpolitica);
          setValue('tokenid', tokenid);
          setValue('surname', apellidoParam);
          setValue('documentType', Number(tipodocumento));
          console.log(tipodocumento)
          setValue('personType', Number(tipocliente));
          setValue('documentNumber', numdocumento);
          setValue('id_portal', id)
          console.log('1', readresponse.data.data.tipoPersona)
          setIsNaturalPerson(true);
          handleTabChange(readresponse.data.data.tipoPersona)
         }
         else if (readresponse.data.data.tipoPersona =='2') {
          document.getElementById("btnregistrar").disabled = true;
          const id = readresponse.data.data.id;
          const tokenid = readresponse.data.data.tokenid;
          const razonsocialParam = readresponse.data.data.razonsocial;
          const tipocliente = readresponse.data.data.tipoRegistrador;
          const numdocumento = readresponse.data.data.numeroDocumento;
          const flagpolitica = readresponse.data.data.flagPoliticaSeguridad;
          if(flagpolitica){
            document.getElementById("btnregistrar").disabled = true;
            document.getElementById("chkterminos").disabled = true;
            // var miCheckboxlegal = document.getElementsByName('legalAcceptanceTerms')[0];
            // miCheckboxlegal.disabled = true;
            
          }


          setValue('legalAcceptanceTerms', flagpolitica);
          setValue('businessName', razonsocialParam);
          setValue('tokenid', tokenid);
          setValue('businessDocumentNumber', numdocumento);
          setValue('legalPersonType', Number(tipocliente));
          setIsLegalPerson(true)
          setValue('id_portal', id)
          handleTabChange(readresponse.data.data.tipoPersona)
          setIsLegalPerson(true);
          // setIsNaturalPerson("1" == tipopersonaParam);
          // setIsLegalPerson("2" == tipopersonaParam);
        }
        
    })();
    
   
   
    //  const tipopersonaParam = params.get('tipopersona');
   
    // console.log(tipopersonaParam);

    // console.log(typeof(tipopersonaParam))
    // SetDefaultTabactive(tipopersonaParam);
    
    // if (tipopersonaParam ==='1'){
    //   const nombreParam = params.get('nombre');
    //   const apellidoParam = params.get('apellido');
    //   const tipodocumento = params.get('tipodocumento');
    //   const tipocliente = params.get('tipocliente');
    //   const numdocumento = params.get('numdocumento');
    //   setValue('name', nombreParam);
    //   setValue('surname', apellidoParam);
    //   setValue('documentType', Number(tipodocumento));
    //   console.log(tipodocumento)
    //   setValue('personType', Number(tipocliente));
    //   setValue('documentNumber', numdocumento);
    //   console.log('1', tipopersonaParam)
    //   // setIsNaturalPerson(true)
    //   handleTabChange(tipopersonaParam)
    // }else if (tipopersonaParam ==='2') {
    
    //   const razonsocialParam = params.get('razonsocial');
    //   const tipocliente = params.get('tipocliente');
    //   const numdocumento = params.get('numdocumento');
    //   setValue('businessName', razonsocialParam);
    //   setValue('businessDocumentNumber', numdocumento);
    //   setValue('legalPersonType', Number(tipocliente));
    //   console.log(razonsocialParam)
    //   console.log(numdocumento)
    //   // setIsLegalPerson(true)
    //   handleTabChange(tipopersonaParam)
    //   // setIsNaturalPerson("1" == tipopersonaParam);
    //   // setIsLegalPerson("2" == tipopersonaParam);
    // }


  }, [])

  useEffect(() => {
    // // Obtén la cadena de consulta de la URL
    // const queryString = window.location.search;

    // // Crea un objeto URLSearchParams para acceder a los parámetros
    // const params = new URLSearchParams(queryString);

    // // Lee el valor del parámetro 'id'
    // const idParam = params.get('id');

    // // Actualiza el estado con el valor del parámetro 'id'

    // setValue('id_portal', idParam)

  }, );
// }, [handleTabChange]);

  const tabTypePersonTitle = [
    {
      key: "1",
      label: (<span><UserOutlined />Persona Natural</span>), 
      children: (
        <PersonaNaturalForm
          control={control}
          active={isNaturalPerson}
          errors={errors}
          watch={watch}
          disabled={isProcess}
        />
      ),
    },
    {
      key: "2",
      label: (<span><BankOutlined />Persona Jurídica</span>),
      children: (
        <FormJuridicPerson
          control={control}
          active={isLegalPerson}
          errors={errors}
          watch={watch}
          disabled={isProcess}
        />
      ),
    },
  ];
  const handleOkRegister = () => {
    setProcess(false);
    setError(false);
    // setButtonProcess(false);
  }
  const buttonRegister = (
    <Button
      type="primary"
      block
      className="button"
      onClick={handleSubmit(onSubmit, onError)}
      // disabled={isButtonProcess}
      id="btnregistrar"
    >
      {" "}
      Registrar
    </Button>
  );
  return (
    <>
        <Modal
          title="Datos del Formulario "
          open={openProcess}
          cancelButtonProps={{ style: { display: 'none' }}}
          okButtonProps={{ style: { display: 'none' } }}
        >
          <Spin tip="Procesando datos...">
            <Alert
              message={<br/>}
              description={<br/>}
              type="info"
            />
          </Spin>
        </Modal>
        <Card actions={[buttonRegister]} style={{paddingLeft: 20, paddingRight: 20}}>
            <Tabs
              centered
              items={tabTypePersonTitle}
              activeKey={defaultTabactive}
              onChange={handleTabChange}
            >
            </Tabs>
        </Card>
       
        <Modal
          title="Formulario de Registro"
          open={isProcess}
          cancelButtonProps={{ style: { display: 'none' }}}
          onOk={handleOkRegister}
        >
          <Result
            status="success"
            title="Formulario de Registro de Datos"
            subTitle="Tu formulario ha sido registrado con éxito."
          />
        </Modal>
        <Modal
          title="Formulario de Registro"
          open={isError}
          cancelButtonProps={{ style: { display: 'none' }}}
          onOk={handleOkRegister}
        >
          <Result
             status="warning"
             title="401"
             subTitle="No se pudo realizar el registro del formulario. Acceso no permitido"
          />
        </Modal>
    </>
  );
};
export default FormRegister;

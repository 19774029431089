import React, { Component } from "react";
import { Layout,Col, Row } from 'antd';
import FooterTemplate from './Footer';
import FormRegister from './FormRegister'

const { Header, Footer, Content } = Layout;

class TemplateComponent extends Component {
  render() {
    return (
        <Layout>
            <Header style={{ display: 'flex', alignItems: 'center', paddingTop: 10, marginTop:10, backgroundColor:"white" }}>
                <div className="logo-institucion" >
                <a href="https://www.gloria.com.pe/" target="blank" title="Grupo Gloria - 81 Años">
                    { <img  className="logo" src={require("../assets/images/gloria-logo.png")} alt="Grupo Gloria"/> }
                </a>
                </div>
            </Header>
            <Content  style={{padding: 24,margin: 0,minHeight: 700,ackground: "#F7F7F7"}}>
                <Row>
                    <Col  xs={0} sm={0} md={8} lg={8} xl={8}></Col>
                    <Col  xs={24} sm={24} md={24} lg={8} xl={8}><FormRegister/></Col>
                    <Col  xs={0} sm={0} md={8} lg={8} xl={8}></Col>
                </Row>
            </Content>
            <Footer style={{ textAlign: 'center', background:"#EFEFEF"}}>
                <FooterTemplate/>
            </Footer>
        </Layout>
    );
  }
}
export default TemplateComponent;
import React, { useState } from 'react';
import { Modal,Button } from 'antd';

const Terms = () => {
    const [isModalOpen, setIsModalOpen] = useState(false);
    const showModal = () => {
      setIsModalOpen(true);
    };
    const handleOk = () => {
      setIsModalOpen(false);
    };
    const handleCancel = () => {
      setIsModalOpen(false);
    };
    
    return (
        <>
            <p>
            Para que puedas revisar nuestros términos y condiciones expuestos en nuestra Política de Privacidad ingresa a: <a onClick={showModal}>Políticas de Privacidad</a>
            </p>
            <Modal title="Política de Privacidad" 
                    open={isModalOpen} 
                    onOk={handleOk} 
                    onCancel={handleCancel}
                    footer={[
                        <Button key="back" onClick={handleCancel}>
                          Cerrar
                        </Button>  
                    ]}
            >
              <div>
               <iframe id="iframe"
                  title="Privacy Policy"
                  src="https://www.gloria.com.pe/images/Política de Privacidad.pdf"
                  width="100%"
                  height="100%"
                  type="application/pdf"
                ></iframe>
                </div>
            </Modal>
        </>
    )
}
export default Terms;
import React, { useMemo } from "react";
import { Divider, Form, Input, Select, Checkbox } from "antd";
import Terms from "./Terms";
import PersonType from "../model/PersonType";
import { Controller } from "react-hook-form";

const FormJuridicPerson = ({ active, control, errors, watch })  => {
  const maxLength = useMemo(() => (active ? 250 : undefined), [active]);
  const rucLength = useMemo(() => (active ? 11 : undefined), [active]);
  const rucPattern = useMemo(() => (active ? /^[0-9]*$/ : undefined), [active]);
  const watchPersonType = watch("legalPersonType");
  const needDescription = useMemo(
    () => watchPersonType === 4,
    [watchPersonType]
  );

  const oncheck = async () => {
    try {

      var miCheckboxlegal = document.getElementsByName('legalAcceptanceTerms')[0];
        
      
        if(miCheckboxlegal.checked) {
          document.getElementById("btnregistrar").disabled = false;
        } else {
          document.getElementById("btnregistrar").disabled = true;
        };
    
      
    } catch (error) {
      console.error(error);
      return  error;
    }
  }

  return (
    <>
      <Form
        labelCol={{ span: 24 }}
        wrapperCol={{ span: 24 }}
        layout="horizontal"
        style={{ paddingTop: 10 }}
      >
        <Form.Item 
        hidden="true" 
        help={errors.id_portal ? '' : ''} validateStatus={errors.id_portal ? 'error' : ''}  >
          <Controller
            name="id_portal"
            control={control}
            //rules={{ required: active, maxLength: maxLength }}
            render={({ field }) => (
              <Input {...field}   className="inputForm" />
            )}
          />
        </Form.Item>
        <Form.Item 
        hidden="true" 
        help={errors.id_portal ? '' : ''} validateStatus={errors.id_portal ? 'error' : ''}  >
          <Controller
            name="idtoken"
            control={control}
            //rules={{ required: active, maxLength: maxLength }}
            render={({ field }) => (
              <Input {...field}   className="inputForm" />
            )}
          />
        </Form.Item>
        <Form.Item help={errors.businessName ? '' : ''} validateStatus={errors.businessName ? 'error' : ''} >
          <Controller
            
            name="businessName"
            control={control}
            //rules={{ required: active, maxLength: maxLength }}
            render={({ field }) => (
              <Input
                {...field}
                placeholder="Razón Social"
                className="inputForm"
                maxLength={250}
                disabled="true"
              />
            )}
          />
        </Form.Item>
        <Form.Item help={errors.businessDocumentNumber ? '' : ''} validateStatus={errors.businessDocumentNumber ? 'error' : ''}>
          <Controller
            name="businessDocumentNumber"
            control={control}
            rules={{
             //required: active,
              maxLength: rucLength,
              minLength: rucLength,
              pattern: rucPattern,
            }}
            render={({ field }) => (
              <Input {...field} placeholder="RUC" className="inputForm" disabled="true" maxLength={rucLength} minLength={rucLength}/>
            )}
          />
        </Form.Item>
        <Divider>Ayúdanos a Identificarte</Divider>
        <Form.Item label="Eres:" className="labelSelect" help={errors.legalPersonType ? '' : ''} validateStatus={errors.legalPersonType ? 'error' : ''}>
          <Controller
            name="legalPersonType"
            control={control}
            defaultValue={null}
           // rules={{ required: active }}
            render={({ field }) => (
              <Select
                {...field}
                className="selectForm"
                placeholder="Elegir"
                options={PersonType[1]}
                disabled="true"
              ></Select>
            )}
          />
        </Form.Item>

        {needDescription && (
          <Form.Item help={errors.businessDocumentNumber ? '' : ''} validateStatus={errors.businessDocumentNumber ? 'error' : ''}>
            <Controller
              name="legalPersonTypeDescription"
              control={control}
              //rules={{ required: active, maxLength: maxLength }}
              defaultValue=""
              render={({ field }) => (
                <Input {...field} placeholder="Otros" className="inputForm" maxLength={250} />
              )}
            />
          </Form.Item>
        )}

        <Form.Item help={errors.legalAcceptanceTerms ? '' : ''} validateStatus={errors.legalAcceptanceTerms ? 'error' : ''}>
          <div className="divTerms">
            <Controller
              name="legalAcceptanceTerms"
              control={control}
             // rules={{ required: active }}
              render={({ field }) => (
                <Checkbox
                  {...field}
                  checked={field.value}
                  onChange={field.onChange}
                  className="checkForm"
                  id="chkterminoslegal"
                  onClick={oncheck}
                >
                  He leído y estoy de acuerdo con la Política de Privacidad de Leche Gloria S.A.
                </Checkbox>
              )}
            />
          </div>
        </Form.Item>
        <Terms />
      </Form>
    </>
  );
};

export default FormJuridicPerson;

import React from 'react';
import RegisterApp from './views/RegisterApp';
import './assets/css/App.css';

function App() {
  return (
    <div className="App">
      <RegisterApp />
    </div>
  );
}

export default App;
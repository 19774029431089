import axios from 'axios';

export const AuthorizationServices = async () => {
    try {
           
        const domainUrl =process.env.REACT_APP_API_URL + "authentication";
        
        

        const endpoint = {
            method: 'get',
            // url: `${domainUrl}/oauth2/token`,
            url: `${domainUrl}`
          };
          
          const awsResponse = await axios(endpoint);
          return awsResponse.data;
    } catch (error) {
        throw error;
    }
};

import * as React from 'react';
import {Card} from 'antd';
import LineHeader from '../components/LineHeader';
import Template from '../components/Template';

const RegisterApp = () => {
  const mainLayout = (
    <React.Fragment>
      <Template/>
    </React.Fragment>
  );

  return (
    <div>
      <LineHeader/>
      <Card style={{ bordered: 'false'}}>{mainLayout}</Card>
    </div>
  );
};

export default RegisterApp;